import * as React from "react";

import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image";

import { OptionsObject, useSnackbar } from "notistack";

const HomePage = () => {
  const [lastName, setLastName] = React.useState("");
  const [von, setVon] = React.useState("");
  const [vonHelperText, setVonHelperText] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const defaultSnackbarProps:OptionsObject = {variant: "error", autoHideDuration: 3000, anchorOrigin: {vertical: "bottom", horizontal: "center"}, preventDuplicate: true};

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  }
  
  const handleVonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVon(event.target.value);
  }

  const handleCheckStatus = () => {
    var isError = false;
    if (lastName.length === 0) {
      enqueueSnackbar("Please enter your last or business name.", defaultSnackbarProps);
      isError = true;
    }

    if (von.length !== 8) {
      enqueueSnackbar("Order Number Must Be 8 Characters Long.", defaultSnackbarProps);
      isError = true;
    }

    if (isError) {
        return;
    }

    setIsLoading(true);
    fetch(`${process.env.GATSBY_API_URL}/api/v1/tracker/orderStatus?lastName=${encodeURIComponent(lastName as string)}&orderNumber=${encodeURIComponent(von as string)}`)
      .then(
        (res) => {
          if (res.status !== 200) {
            return Promise.reject({
              status: res.status,
              isError: res.status !== 404,
              isNotFound: res.status === 404
            });
          }

          return res.json();
        },
        (error) => {
          return Promise.reject({
            status: null,
            isError: true,
            error: error
          });
        }
      )
      .then(
        (result) => {
          setIsLoading(false);
          navigate("/orderstatus", {state: {orderStatus: result, lastName: lastName, orderNumber: von}});
        },
        (error) => {
          setIsLoading(false);
          enqueueSnackbar(error.isNotFound ? "Your order was found but we do not have any updates for it yet. Please check back again later." : "Order Status could not be found. Please check your order number, your last name and try again.", {...defaultSnackbarProps, autoHideDuration: 15000, onClick: () => closeSnackbar(), variant: error.isError ? "error" : "warning"});
        }
      )
      .catch((error) => {
        setIsLoading(false);
        console.error("Data Error:", error);
        enqueueSnackbar("An error occurred. Please try again later.", {...defaultSnackbarProps, autoHideDuration: 15000, onClick: () => closeSnackbar()});
      });
  }

  return (
    <div>
      <Backdrop
  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  open={isLoading}
  onClick={() => setIsLoading(false)}
>
  <CircularProgress color="inherit" />
</Backdrop>
      <Container>
        <Grid
          container
          className="py-5"
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
                <StaticImage
                  src="../../images/icon.png"
                  alt="WaitingForJeep"
                  placeholder="blurred"
                  width={100}
                  height={100}
                  loading="eager"
                  objectFit="contain"
                ></StaticImage>
                </Grid>
          <Grid item className="py-3 px-xs-1 px-lg-3">
            <Typography variant="h1" component="div" align="center">
              Find Your Order Status
            </Typography>
            <Container className="px-3"><Typography variant="overline" component="h2" align="center">
              With full support for U.S. Chrysler, Jeep, Dodge &amp; RAM Orders
            </Typography></Container>
          </Grid>
          <Grid item container className="p-3">
            <Container maxWidth="sm">
              <FormControl fullWidth>
                <TextField
                  id="von"
                  value={von}
                  onChange={handleVonChange}
                  label="Vehicle Order Number"
                  variant="filled"
                  type="type"
                  autoComplete="off"
                  required
                  inputProps={{
                    inputMode: "text",
                    pattern: "[0-9A-Za-z]{8}",
                  }}
                />
              </FormControl>
            </Container>
          </Grid>
          <Grid item container className="p-3">
            <Container maxWidth="sm">
              <FormControl fullWidth>
                <TextField
                  id="lastName"
                  label="Last Name / Business Name"
                  variant="filled"
                  autoComplete="off"
                  value={lastName}
                  required
                  onChange={handleLastNameChange}
                />
              </FormControl>
            </Container>
          </Grid>
          <Grid item container className="p-3" justifyContent="center">
            <Button variant="outlined" size="large" onClick={handleCheckStatus} color="primary">
              Check Status
            </Button>
          </Grid>
        </Grid>
      </Container>
      {/* {alertMessages.map((message, index) => (<CustomSnackbar key={index} message={message} alertType="error"/>))} */}
    </div>
  );
};

export default HomePage;
